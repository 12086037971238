import * as React from "react";
import { motion, useAnimation } from "framer-motion";

export function Example() {
  const animation = useAnimation();

  return (
    <div
      style={{
        width: 400,
        height: 400,
        position: "relative"
      }}
    >
      <motion.div
        style={{
          width: 120,
          height: 120,
          borderRadius: 25,
          backgroundColor: "#fff",
          position: "absolute",
          left: 40,
          top: 40,
          cursor: "pointer"
        }}
        animate={animation}
        onTap={() =>
          animation.start({
            x: [0, 200, 200, 0, 0],
            y: [0, 0, 200, 200, 0]
          })
        }
        transition={{ duration: 4, times: [0, 0.08, 0.16, 0.25, 1] }}
      />
    </div>
  );
}
